import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {isAreaTicket, isRowTicket, isTableTicket} from '@wix/wix-events-commons-statics'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {SeatInfoType} from '../../enums'
import {Divider} from '../../../components/details-page/Widget/components/status-screens/common/divider'
import {EventText} from '../../../components/members-page/Widget/components/styled-components/event-text'
import {useCurrencyFormatter} from '../../hooks/currency'
import {SeatInfo, TicketWithSeatingProps} from './interfaces'
import s from './ticket-with-seating.scss'
import {AreaSeatInfo} from './area-seat-info'
import {TableRowSeatInfo} from './table-row-seat-info'

export const TicketWithSeating = ({ticket}: TicketWithSeatingProps) => {
  const {t} = useTranslation()
  const seatInfo = getSeatInfo(ticket, t)
  const pricingOptionName = ticket.ticketDetails?.pricingOptionName
  const {getFormattedMoney} = useCurrencyFormatter()
  const price = getFormattedMoney(ticket.price)

  const getSeatInfoCol = () => {
    switch (seatInfo.type) {
      case SeatInfoType.AREA: {
        return <AreaSeatInfo ticket={ticket} seatInfo={seatInfo} />
      }
      case SeatInfoType.TABLE:
      case SeatInfoType.ROW:
        return <TableRowSeatInfo ticket={ticket} seatInfo={seatInfo} />
      default:
        return null
    }
  }

  return (
    <>
      <div className={s.row} role="row">
        <div className={s.colTicket} data-hook={DH.TICKET_NAME} role="cell">
          <EventText>{ticket.name}</EventText>
        </div>
        {getSeatInfoCol()}
        <div className={s.colTotal} role="cell" data-hook={DH.TICKET_PRICE}>
          <EventText>{pricingOptionName ? `${pricingOptionName} - ${price}` : price}</EventText>
        </div>
      </div>
      <Divider />
    </>
  )
}

const getSeatInfo = (ticket: wix.events.ticketing.Ticket, t): SeatInfo => {
  const sectionName = ticket.ticketDetails?.sectorName
  if (isRowTicket(ticket)) {
    return {
      section: sectionName,
      type: SeatInfoType.ROW,
      seatingInfo: {
        primary: {header: t('order.seating.row'), text: ticket.ticketDetails.rowNumber},
        secondary: {header: t('order.seating.seat'), text: ticket.ticketDetails.seatNumber},
      },
    }
  }
  if (isAreaTicket(ticket)) {
    return {
      section: sectionName,
      type: SeatInfoType.AREA,
      seatingInfo: {
        primary: {header: t('order.seating.area'), text: ticket.ticketDetails.areaName},
      },
    }
  }
  if (isTableTicket(ticket)) {
    return {
      section: sectionName,
      type: SeatInfoType.TABLE,
      seatingInfo: {
        primary: {header: t('order.seating.table'), text: ticket.ticketDetails.tableName},
        secondary: {header: t('order.seating.seat'), text: ticket.ticketDetails.seatNumber},
      },
    }
  }
}
