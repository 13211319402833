import React from 'react'
import {getPaidPlanPercentDiscount, getCheckoutSummary, getTaxLabel} from '@wix/wix-events-commons-statics'
import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {EventText} from '../../styled-components/event-text'
import {Divider} from '../../styled-components/divider'
import {useCurrencyFormatter} from '../../../../../../commons/hooks/currency'
import s from './order-summary.scss'
import {OrderSummaryProps} from '.'

export const OrderSummary = ({invoice, t}: OrderSummaryProps) => {
  const {getFormattedMoney} = useCurrencyFormatter()
  const {subtotal, discount, paidPlanDiscount, tax, total, addedFee} = getCheckoutSummary(invoice, getFormattedMoney)
  const taxLabel = getTaxLabel(invoice)

  return (
    <div className={s.summary}>
      {subtotal && (
        <>
          <div className={s.subtotals} data-hook={DH.SUBTOTALS_CONTAINER}>
            <Price label={t('order.subtotal')} value={subtotal} />
            <Price
              label={t('order.paid-plan', {discount: getPaidPlanPercentDiscount(invoice)})}
              value={paidPlanDiscount}
            />
            <Price label={discount ? `${t('order.coupon.code')}${invoice.discount.code}` : null} value={discount} />
            <Price label={taxLabel} value={tax} dataHook={DH.TAX} />
            <Price label={t('order.serviceFee')} value={addedFee} />
          </div>
          <Divider />
        </>
      )}
      <div className={s.totals}>
        <div>
          <EventText large>{t('order.total')}</EventText>
        </div>
        <div>
          <EventText large>{total}</EventText>
        </div>
      </div>
    </div>
  )
}

const Price = ({label, value, dataHook = null}) => {
  if (!value) {
    return null
  }

  return (
    <div className={s.subtotalsRow} data-hook={dataHook}>
      <EventText>{label}</EventText>
      <EventText>{value}</EventText>
    </div>
  )
}
